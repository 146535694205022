.root {
	position: relative;
	background-color: var(--token-color-page-faint);

	& ul {
		--columns: 2;

		display: grid;
		grid-template-columns: 1fr 1fr;
		gap: var(--hdsplus-spacing-06);
		list-style: none;
		margin: 0;
		padding: var(--hdsplus-spacing-08) 0;
		flex-wrap: wrap;

		@media (--medium-up) {
			display: flex;
			flex-direction: row;
			justify-content: center;
			column-gap: var(--hdsplus-spacing-10);
		}

		@media (--large) {
			column-gap: var(--hdsplus-spacing-14);
		}
	}
}

.container {
	composes: g-grid-container from global;
}

.link {
	composes: hdsplus-typography-body-200 from global;
	margin: 0;
	padding: var(--hdsplus-spacing-05) 0;
	border: 0;
	color: var(--token-color-foreground-strong);

	@media (--medium-up) {
		padding: var(--hdsplus-spacing-05) var(--hdsplus-spacing-04);
	}
}
